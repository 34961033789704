import { Labelled, LabelledString } from '../../../services/tools/TypeHelper';
import { StylesConfig } from 'react-select';
import * as apiFlow from '../../../services/apiFlowService';
import { format } from 'date-fns';
import { AuditType, FlowOutput, FlowStatusFilter, FullFlowStatus } from '../../../services/apiFlowService';

// Fake datas
export const tableStatus: Array<Labelled<FlowStatusFilter>> = [
    {
        label: 'Non envoyé',
        value: 'notSent',
    },
    {
        label: 'Envoyé sans rdv',
        value: 'sentNoRdv',
    },
    {
        label: 'Envoyé avec rdv',
        value: 'sentWithRdv',
    },
    {
        label: 'Commande finalisée',
        value: 'orderCompleted',
    },
    {
        label: 'Pré-visite finalisée',
        value: 'preVisitCompleted',
    },
];

/**
 * Pour le changement de couleurs du react-select
 */
export const panelStyles: StylesConfig<LabelledString, false> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '100px',
        height: '43px',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

export type FlowOutputRow = {
    createdAt: string;
    type: AuditType;
    customerName: string;
    state: FlowStatusFilter;
    select: string;
    url: string;
    id: string;
    comment: string;
    mar: boolean;
    marContactedAt?: boolean;
};

export type AgencyFlowOutputRow = {
    createdAt: string;
    agentName: string;
    type: AuditType;
    customerName: string;
    state: FlowStatusFilter;
    url: string;
    id: string;
    comment: string;
    mar: boolean;
    marContactedAt?: boolean;
};

export const convertToRow = (flows: Array<FlowOutput>): Array<FlowOutputRow> => {
    const a: FlowOutputRow[] = flows.map((f) => {
        const mar =
            f.dossierRenoDAmpleur !== null &&
            f.dossierRenoDAmpleur !== undefined &&
            f.dossierRenoDAmpleur.renoDampleurParams?.elligible === true &&
            f.dossierRenoDAmpleur.renoDampleurParams?.marShouldContactCustomer === true;
        const marContactedAt = !mar ? undefined : f.dossierRenoDAmpleur?.marContactedAt !== null && f.dossierRenoDAmpleur?.marContactedAt !== undefined;

        const result: FlowOutputRow = {
            createdAt: format(new Date(f.updatedAt ?? f.createdAt), 'dd/MM/yyyy - kk:mm'),
            type: f.auditType,
            customerName: f?.customer?.userLastname ?? 'Non renseigné',
            state: statusToStatusFilter(f.state),
            select: '',
            url: '/espace-agent/list-audit/' + f.id,
            id: f.id,
            comment: f.comment ?? '',
            //mar: f.mar !== null,
            mar,
            marContactedAt,
        };
        return result;
    });
    return a;
};

export const convertToAgencyRow = (flows: Array<FlowOutput>): Array<AgencyFlowOutputRow> => {
    const a: AgencyFlowOutputRow[] = flows.map((f) => {
        const mar =
            f.dossierRenoDAmpleur !== null &&
            f.dossierRenoDAmpleur !== undefined &&
            f.dossierRenoDAmpleur.renoDampleurParams?.elligible === true &&
            f.dossierRenoDAmpleur.renoDampleurParams?.marShouldContactCustomer === true;

        const marContactedAt = !mar ? undefined : f.dossierRenoDAmpleur?.marContactedAt !== null && f.dossierRenoDAmpleur?.marContactedAt !== undefined;

        const result: AgencyFlowOutputRow = {
            createdAt: format(new Date(f.updatedAt ?? f.createdAt), 'dd/MM/yyyy - kk:mm'),
            agentName: f.agent?.firstname + ' ' + f.agent?.lastname,
            type: f.auditType,
            customerName: f?.customer?.userLastname ?? 'Non renseigné',
            state: statusToStatusFilter(f.state),
            url: '/espace-agent/list-audit/' + f.id,
            id: f.id,
            comment: f.comment ?? '',
            mar,
            marContactedAt,
        };
        return result;
    });
    return a;
};

const statusToStatusFilter = (status: FullFlowStatus): FlowStatusFilter => {
    switch (status) {
        case 'Created':
        case 'AuditSent':
        case 'RdvRequested':
            return 'notSent';

        case 'WithoutRdv':
            return 'sentNoRdv';

        case 'RdvSigned':
        case 'WithoutDevis':
        case 'WithoutCommand':
        case 'DevisSent':
            return 'sentWithRdv';

        case 'CommandSigned':
        case 'WithoutPrevisit':
            return 'orderCompleted';

        case 'WithoutInstallation':
        case 'WithInstallation':
            return 'preVisitCompleted';

        default:
            return 'notSent';
    }
};

export type PaginationRequest = {
    pageIndex: number;
    elementsPerPage: number;
};

export const filterStatusName = (status: apiFlow.FlowStatusFilter) => {
    switch (status) {
        case 'notSent':
            return 'Non envoyé';

        case 'sentNoRdv':
            return 'Envoyé sans rdv';

        case 'sentWithRdv':
            return 'Envoyé avec rdv';

        case 'orderCompleted':
            return 'Commande finalisée';

        case 'preVisitCompleted':
            return 'Pré-visite finalisée';

        default:
            return '';
    }
};
